import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default (props) => {
  var dispatcher = useDispatch();
 
  const handleImageError = (event) => {
    event.target.src =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKsAAACUCAMAAADbGilTAAAAVFBMVEXv8fNod4fy9PZhcYL5+vuTnajR1dqcpbDV2dx6h5RldIWBjZlYanz29/ldbn/s7vDf4uZ1gpCqsrrJztRufYzl6Ou4vsa+xMuLlqJLX3OwuL+jrLXY3CcgAAACj0lEQVR4nO2a25aiMBBFpUAIMRUDgjr6//85TnsDhe40gSTOOvvZBZtax6RyWa0AAAAAAAAAAAAAAAAAAABAHFA2C15U82I9A3VLy6vWSqczoBKxtCwJJZNZSNfbhV2zWs+jmshm6cJmRTqTa7J4CD7SVU7Hu2s5nUp6dZWb1eQXUa58u07Gv+v0wRGuA8B1GQZdiYnZ+sUhXYnzfX06GNuONKArm00i01Q2teWQG86VDultJlOlsXp5MFcS6WN+1xu2eUQw16zs9F3qYCMbypXzbustS5tHBHOte+2sVasfzLXor752n+pKF2JyPY1ngMzukEfkSmL0v8V5qZRcv5c2vjGLrwOvXr/NZuHmApN05oJnS0D3wUwfty9KAefYXfOwah8/411z/wR9fIlBwN6FzPGP0lqp07OALJ7VvsSg3y8G7V+zdnfa5/zMKvVms9cYhO61s27pKG/6o27/DxbTGoZF87qVqIvOt0Tk2u0SBysbj+tbAN5ko3EdCMBrDGJxZSPHtr11catsJK6UJ+M79Kq4TgpxuHJefXeYoM5fMYjCldvRANxi8CUbgyuJH1T/xeCS2QhcWXwbgLtsRuFduW1sTj10zcFdaWxcfZM9swjrSqKyPUtS57CubKwCcKvs0fc5TK8nNJYBuHL/bQjXXwSgRwBXNtNUQ6y32omqAdaxU6vq35VNOVXV+37WdnJVfbteAuBwo8Srq0sAPLvS1knVpyu5VdWnK5uN49UXb66uAfDoemxdq+rvbk7lrurN9cdlYESucwDXD3Xdq5lMZWV33cCFZGAreIqqrq1uGzghjhdbV1JZ2V45cYG2Ip8BM3iuPL/tLPgwBQAAAAAAAAAAAAAAAAAAAP8dfwFsiTbC1wNGkgAAAABJRU5ErkJggg=="; // Set your fallback image URL here
  };
  return (
    <div
      onClick={() => {
        dispatcher({ type: "set-selectedSchool", payload: props.item });
        props.setrevmodal(true);
      }}
      className="bg-white rounded shadow  border   hover:border-primary pb-2 "
    >
      <img
        onError={handleImageError}
        className="w-full border border-primary h-[200px] bg-contain"
        alt="School logo empty"
        src={props.item.schoollogo}
      />
      <p className="font-semibold  text-primary ml-3">
        {props.item.schoolname}
      </p>
      <p className="font-light text-sm ml-3 text-primary ">
        {props.item.address}
      </p>
      <p className="font-light text-sm ml-3 text-gray-500 ">
        {props.item.schooltype}
      </p>
      <p className="font-light  text-sm ml-3 text-primary ">
        {props.item.creatorrole} : {props.item.user}{" "}
      </p>
    </div>
  );
};
