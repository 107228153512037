import logo from "./logo.svg";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./State/store";
import "./index.css";
import "./output.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { RegisterStaff } from "./Pages/Auth/Registration";
import { Login } from "./Pages/Auth/Login";
import { Certificates as Admincerview } from "./Components/Admin/Certificates";

import { Dashboard } from "./Pages/Userprotected/Dashboard";
import { AccountDashboard } from "./Pages/Userprotected/Accountdashbaord";
import { AllSchools } from "./Pages/Userprotected/AllSchools";
import { ReviewSchool } from "./Pages/Userprotected/ReviewSchool";
import { ReviewEntry } from "./Pages/Userprotected/ReviewEntry";
import { Requestchange } from "./Pages/Auth/RewuestReset";
import { ResetPass } from "./Pages/Auth/ResetPass";
import { Admin_Fees } from "./Components/Admin/Fees/Fees_Admin";
import { SelectedSchool } from "./Components/Admin/SeclectedSchool";
import { AdminFacilities } from "./Components/Admin/Facility/AdminFacility";
import Payments from "./Components/Admin/Payment/Payments";
import { MapAdmin } from "./Components/Admin/MapAdmin";
import ComposeMsg from "./Pages/Userprotected/message/ComposeMsg";
import Notificatons from "./Pages/Userprotected/Notificatons";
import Allstaffs from "./Pages/Userprotected/Allstaffs";
import SelectedStaff from "./Components/Admin/SelectedStaff";
import ComposeStaffMsg from "./Pages/Userprotected/message/ComposeStaffMsg";

function App() {
  return (
    <div id="rot" className="bg-customGray h-screen overflow-y-scroll">
      <Provider store={store}>
        <Router
        //  basename="/govstaff"
        >
          <ToastContainer
            toastStyle={{ backgroundColor: "white", color: "black" }}
          />

          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<RegisterStaff />} />
            <Route path="/reviewschool" element={<ReviewSchool />} />
            <Route path="/resetpassword" element={<Requestchange />} />
            <Route path="/changepass/:userId" element={<ResetPass />} />
            <Route path="/reviewentry" element={<ReviewEntry />} />
            <Route path="/dashboard" element={<Dashboard />}>
              <Route path="" element={<AccountDashboard />} />
              <Route path="allschools" element={<AllSchools />} />
              <Route path="school" element={<SelectedSchool />} />
              <Route path="staffs" element={<Allstaffs />} />
              <Route path="staff" element={<SelectedStaff />} />
              <Route path="messages" element={<Notificatons />} />
              <Route path="message" element={<ComposeMsg />} />
              <Route path="staffmessage" element={<ComposeStaffMsg />} />
              <Route path="certificates" element={<Admincerview />} />
              <Route path="fees" element={<Admin_Fees />} />
              <Route path="facilities" element={<AdminFacilities />} />
              <Route path="payments" element={<Payments />} />
              <Route path="mapadmin" element={<MapAdmin />} />
              <Route path="ongoing" element={<AccountDashboard />} />
              <Route path="reviewed" element={<AccountDashboard />} />
            </Route>
          </Routes>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
